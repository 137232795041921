<template>
<div class="inviteFriends page">
  <headers title="邀请好友"></headers>
  <div class="scroll">
    <div class="invite">
      <div class="qrCode">
        <div class="img">
          <van-image :src="qrCode" width="129" height="129">
            <template v-slot:error>加载失败</template>
          </van-image>
        </div>
        <p>扫描二维码即可分享好友哟</p>
      </div>
      <van-button class="btn" round block type="info" native-type="submit" color="linear-gradient(180deg,#9eb0ff,#4969ff)" @click="copyUrl()">复制推广链接</van-button>
    </div>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";
import { Toast } from 'vant';

export default {
  name: "inviteFriends",
  components: {
    headers
  },
  data() {
    return {
      qrCode: '',
      userInfo: {}
    }
  },
  mounted() {
    this.userInfo = JSON.parse( this.$store.getters["getUserInfo"])
    this.getQrCode()
  },
  methods: {
    getQrCode() {
      $api.sharecode({
        token: this.$store.getters["getToken"]
      }).then(res => {
        this.qrCode = res.data
      })
    },
    copyUrl(){
      let url = 'https://xinyue.incocmt.cn/index.html#/login?pid='+this.userInfo.u_yqm;
      let domInput = document.createElement('input');
      domInput.value = url;
      document.body.appendChild(domInput);  // 添加input节点
      domInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast('链接复制成功')
      domInput.remove()
    }
  }
}
</script>

<style scoped lang="scss">
.inviteFriends {
  .scroll {
    background: url("../../../static/img/yaoqing.png") no-repeat;
    background-size: 100% 100%;
    height: 100%;
    position: relative;
    .invite {
      background: url("../../../static/img/fdass.png") no-repeat;
      background-size: 100% 100%;
      width: 315px;
      height: 342px;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      padding: 15px;
      .img {
        width: 129px;
        border: 1px solid #ddd;
        margin: 25px auto 0;
        overflow: hidden;
      }
      p {
        font-size: 14px;
        margin-top: 15px;
      }
      .btn {
        margin-top: 60px;
      }
    }
  }
}
</style>